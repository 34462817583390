<template>
    <div>
        <form class="max800" @submit.prevent="updateRecord">
            <!-- paste from hq below here -->
            <h1 class="bottom-20">Insurance Payer</h1>
            <div class="flex wrap">
                
                <div class="fullwidth bottom-5">
                    <Checkbox
                        id="active"
                        class="right-30"
                        value=1
                        v-model="record_data.active"
                        label="Active"
                        :disabled="!isEditable" />

                    <Checkbox
                        id="claims_enrollment"
                        class="right-30"
                        value=1
                        v-model="record_data.claims_enrollment"
                        label="Claims Enrollment"
                        :disabled="!isEditable" />
                    <Checkbox
                        id="attachments"
                        value=1
                        v-model="record_data.attachments"
                        label="Attachments"
                        :disabled="!isEditable" />
                </div>

                <div class="fullwidth flex colx2 space-between bottom-20"><Input
                        type="text"
                        name="payer_name"
                        id="payer_name"
                        placeholder="Payer Name"
                        v-model="record_data.payer_name"
                        label="Payer Name"
                        :disabled="!isEditable"
                    />
                    <Input
                        type="text"
                        name="payer_id"
                        id="payer_id"
                        placeholder="Payer Id"
                        v-model="record_data.payer_id"
                        label="Payer Id"
                        :disabled="!isEditable" />
                </div>
                
                <div class="fullwidth flex center bottom-20">
                    <Checkbox
                        id="era"
                        class="top-20 right-30"
                        value=1
                        v-model="record_data.era"
                        label="Era"
                        :disabled="!isEditable" />
                    <Input
                        type="text"
                        name="era_enrollment_form_pdf_link"
                        id="era_enrollment_form_pdf_link"
                        class="flex-1"
                        placeholder="Era Enrollment Form Pdf Link"
                        v-model="record_data.era_enrollment_form_pdf_link"
                        label="Era Enrollment Form Pdf Link"
                        :disabled="!isEditable" />
                </div>

                <div class="fullwidth flex colx3 space-between">
                    <UsStates
                        v-model="record_data.payer_state"
                        id="payer_state"
                        name="payer_state"
                        label="Payer State"
                        :readonly="!isEditable" />
                    <Input
                        type="text"
                        name="payer_zip"
                        id="payer_zip"
                        placeholder="Payer Zip"
                        v-model="record_data.payer_zip"
                        label="Payer Zip"
                        :disabled="!isEditable" />
                    <Input
                        type="text"
                        name="tin"
                        id="tin"
                        placeholder="Tin"
                        v-model="record_data.tin"
                        label="Tin"
                        :disabled="!isEditable" />
                </div>

                <!--form_extra-->
            </div>

            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    const api_root = 'insurance_payers'

    export default {
        name: 'InsurancePayerDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    payer_name : '',
                    payer_id : '',
                    claims_enrollment : 0,
                    era : 0,
                    era_enrollment_form_pdf_link : '',
                    attachments : 0,
                    payer_state : '',
                    payer_zip : '',
                    tin : '',
                },

                isEditable : 1,

                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }

                }
            },
            async addNewRecord() {
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        const res = await this.$api.post(this.record_actions.createNewRecord(), this.record_data)

                        this.$toasted.success('Successfully created new Record.')
                        await this.$router.push({ name: 'InsurancePayerDetail', params: { id: res.data.id } })
                    } catch (err) {
                        this.$toasted.error('Failed to create Record. Please try again later.')
                    }
                }
            },
            async updateRecord(e) {
                e.preventDefault()
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        const res = await this.$api.put(this.record_actions.updateRecord(this.record_id), this.record_data)
                        const record_id = parseInt(res.data.recId[0])
                        this.$toasted.success('Successfully updated Record.')
                        await this.$router.push({ name: this.name, params: { record_id: record_id } })
                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.')
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            async $route() {
                await this.init()
            },
        },
    }
</script>
